<template>
	<div>
		<TheContainer />
	</div>
</template>

<script>
export default {
	name: "BannerModule",
	components: {
		TheContainer: () => import("../../shared/containers/TheContainer"),
	},
}
</script>

<style lang="scss" scoped></style>
